import { useOidc } from '@axa-fr/react-oidc';
import { Button, Modal, Notification, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { toastNotifications, variants } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useDeleteCurrentUser } from 'api/v3/user/user';

type DeleteAccountModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const DeleteAccountModal = ({ isOpen, onClose }: DeleteAccountModalProps) => {
    const { t } = useTranslation();
    const { logout } = useOidc();

    const { mutateAsync: deleteCurrentUser, isPending: isDeletingCurrentUser } = useDeleteCurrentUser();

    const deleteAccountSchema = z
        .object({
            confirmation: z.string().min(1, 'isRequired')
        })
        .refine(({ confirmation }) => confirmation === t('delete'), {
            message: 'enterConfirmation',
            path: ['confirmation']
        });

    type DeleteAccountSchema = z.infer<typeof deleteAccountSchema>;

    const { getInputProps, onSubmit, errors, reset, isDirty, isValid } = useForm<DeleteAccountSchema>({
        mode: 'controlled',
        validate: zodResolver(deleteAccountSchema),
        validateInputOnChange: true,
        initialValues: { confirmation: '' }
    });

    const handleDeleteAccount = async () => {
        try {
            await deleteCurrentUser();
            toastNotifications.success({ title: t('success'), message: t('accountDeleted') });
            logout('');
            handleClose();
        } catch {
            toastNotifications.error({ title: t('failed'), message: t('accountNotDeleted') });
        }
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    return (
        <Modal opened={isOpen} size="lg" title={`${t('deleteAccount')}?`} onClose={handleClose}>
            <p>{t('deleteAccountModalMessage')}</p>
            <form className="mt-4 flex flex-col gap-6" onSubmit={onSubmit(handleDeleteAccount)}>
                <TextInput
                    data-testid="deleteAccountConfirmation"
                    label={t('confirmation')}
                    placeholder={t('delete')}
                    {...getInputProps('confirmation')}
                    error={errors.confirmation && t(errors.confirmation.toString())}
                    required
                />
                <Notification title={t('dontWorry')} withCloseButton={false}>
                    {t('dontWorryMessage')}
                </Notification>
                <div className="ml-auto">
                    <Button className="mr-2" variant={variants.button.secondary} onClick={handleClose}>
                        {t('cancel')}
                    </Button>
                    <Button
                        disabled={!isDirty() || !isValid()}
                        loading={isDeletingCurrentUser}
                        type="submit"
                        variant={variants.button.danger}
                    >
                        {t('deleteAccount')}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};
