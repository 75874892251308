import { FileButton, Loader } from '@mantine/core';
import { Avatar, variants, Icon } from '@uag/react-core';

type ImageFileButtonProps = {
    imageSource: string;
    onImageSelected: (file: File | null) => void;
    loading?: boolean;
};

export const ImageFileButton = ({ imageSource, onImageSelected, loading }: ImageFileButtonProps) => (
    <div className="relative h-32 w-32">
        <Avatar className="tests absolute" data-testid="userAvatar" size="extra-extra-large" src={imageSource} />
        {loading ? (
            <Loader className="absolute left-1/3 top-1/3 m-auto" variant={variants.loader.loadingAnimation} />
        ) : (
            <FileButton accept="image/png,image/jpeg" multiple={false} onChange={onImageSelected}>
                {(props) => (
                    <button
                        className="absolute h-32 w-32 rounded-full bg-background-default text-text-default opacity-0 hover:bg-opacity-40 hover:opacity-40 focus:bg-opacity-50 focus:opacity-60"
                        {...props}
                        data-testid="userImageButton"
                    >
                        <Icon icon="edit" size="5xl" />
                    </button>
                )}
            </FileButton>
        )}
    </div>
);
