import { Button, InputBase, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';
import { Select, sendTrackingEvent, toastNotifications } from '@uag/react-core';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { default as PhoneInput } from 'react-phone-number-input/input';
import { z } from 'zod';

import { DetailUserModel, Salutation, TenantLoginProviderType, UpdateUserModel } from 'api/v3/models';
import { getGetCurrentUserDetailsQueryKey, useGetSupportedCultureCodes, useUpdateCurrentUser } from 'api/v3/user/user';
import { resources } from 'i18n';
import { HotjarEvents } from 'utils/hotjar';

type ProfileFormProps = {
    user: DetailUserModel;
    className?: string;
};

const userSchema = z.object({
    title: z.string().optional(),
    firstName: z.string().min(2, 'isRequired'),
    lastName: z.string().min(2, 'isRequired'),
    phoneNumber: z.string().optional(),
    mobileNumber: z.string().optional(),
    email: z.string().email().readonly(),
    userName: z.string().readonly(),
    language: z.string().optional(),
});

type UserSchema = z.infer<typeof userSchema>;

const getDefaultValue = (user: DetailUserModel): UserSchema => ({
    title: user.title ?? '',
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    phoneNumber: user.phoneNumber ?? '',
    mobileNumber: user.mobileNumber ?? '',
    email: user.email ?? '',
    userName: user.userName ?? '',
    language: user.language ?? '',
});

export const ProfileForm = ({ user, className }: ProfileFormProps) => {
    const { t } = useTranslation();
    const [previousUser, setPreviousUser] = useState(user);

    const queryClient = useQueryClient();
    const { data: supportedLanguages, isFetched: isSupportedLanguagesFetched } = useGetSupportedCultureCodes();
    const { mutateAsync: updateCurrentUser, isPending: isUpdateUserLoading } = useUpdateCurrentUser();
    const { getInputProps, setValues, onSubmit, errors, isDirty, isValid, resetDirty } = useForm<UserSchema>({
        mode: 'controlled',
        validate: zodResolver(userSchema),
        validateInputOnChange: true,
        initialValues: getDefaultValue(user),
    });

    if (user !== previousUser) {
        setValues(getDefaultValue(user));
        resetDirty();
        setPreviousUser(user);
    }

    const handleSave = async (values: UserSchema) => {
        const data: UpdateUserModel = {
            ...values,
            salutation: Salutation.Undefined,
        };
        try {
            await updateCurrentUser({ data });
            sendTrackingEvent(HotjarEvents.ProfileChanged);
            resetDirty();
            queryClient.invalidateQueries({ queryKey: getGetCurrentUserDetailsQueryKey() });
            toastNotifications.success({
                title: t('success'),
                message: t('dataSaved', { data: t('accountInformation') }),
            });
        } catch {
            toastNotifications.error({
                title: t('failed'),
                message: t('dataNotSaved', { data: t('accountInformation') }),
            });
        }
    };

    const isActiveDirectoryUser = user.loginProviderType === TenantLoginProviderType.AzureAD;
    const showOnlyUsername = !user.email || user.email === user.userName;

    return (
        <form className={clsx(className, 'grid grid-cols-1 gap-4 xs:grid-cols-2')} onSubmit={onSubmit(handleSave)}>
            <TextInput className="flex-1" data-testid="title" label={t('title')} {...getInputProps('title')} />
            <TextInput
                className="xs:col-start-1"
                data-testid="firstName"
                disabled={isActiveDirectoryUser}
                label={t('firstName')}
                {...getInputProps('firstName')}
                error={errors.firstName && t(errors.firstName.toString())}
                required
            />
            <TextInput
                data-testid="lastName"
                disabled={isActiveDirectoryUser}
                label={t('lastName')}
                {...getInputProps('lastName')}
                error={errors.lastName && t(errors.lastName.toString())}
                required
            />
            <InputBase
                component={PhoneInput}
                data-testid="phoneNumber"
                disabled={isActiveDirectoryUser}
                label={t('phoneNumber')}
                {...getInputProps('phoneNumber')}
            />
            <InputBase
                component={PhoneInput}
                data-testid="mobileNumber"
                disabled={isActiveDirectoryUser}
                label={t('mobileNumber')}
                {...getInputProps('mobileNumber')}
            />
            {showOnlyUsername ? (
                <TextInput label={`${t('email')}/${t('userName')}`} disabled {...getInputProps('userName')} />
            ) : (
                <>
                    <TextInput label={t('email')} type="email" disabled {...getInputProps('email')} />
                    <TextInput label={t('userName')} disabled {...getInputProps('userName')} />
                </>
            )}
            <Select
                className={clsx(showOnlyUsername && 'xs:col-start-1')}
                data={Object.keys(resources)
                    .filter((code) => supportedLanguages?.data.includes(code))
                    .map((code) => ({
                        value: code,
                        label: resources[code as keyof typeof resources].Name,
                    }))}
                data-testid="language"
                disabled={!isSupportedLanguagesFetched}
                label={t('language')}
                searchable
                {...getInputProps('language')}
            />
            <Button
                className="mr-auto xs:col-start-1"
                data-testid="save"
                disabled={!isDirty() || !isValid()}
                loading={isUpdateUserLoading}
                type="submit"
            >
                {t('saveProfile')}
            </Button>
        </form>
    );
};
