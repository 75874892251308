import { Center } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { DataTable, ItemsPerPageType, Pagination } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCurrentUsersActivityLog } from 'api/v3/user/user';
import { getColumns } from './getColumns';

const columns = getColumns();
export const ProfileActivity = () => {
    const { t } = useTranslation();
    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageType>(25);
    const [page, setPage] = useState(1);

    const { ref: paginationRef, width: paginationWidth } = useElementSize();

    const { data: userActivities, isLoading: isLoadingUserActivities } = useGetCurrentUsersActivityLog({
        $top: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $includeTotalCount: true,
    });

    if (!isLoadingUserActivities && (!userActivities || userActivities.data.length <= 0)) {
        return (
            <Center>
                <p className="p-8">{t('noUserActivity')}</p>
            </Center>
        );
    }
    const totalPages =
        userActivities && userActivities.metadata?.paging?.totalCount ? Math.ceil(userActivities.metadata.paging.totalCount / itemsPerPage) : 1;

    return (
        <>
            <DataTable columns={columns} data={userActivities?.data || []} isLoading={isLoadingUserActivities} withHeader />
            <Pagination
                ref={paginationRef}
                className="mt-8"
                disabled={isLoadingUserActivities}
                itemsPerPage={paginationWidth > 570 ? itemsPerPage : undefined}
                page={page}
                totalPages={totalPages}
                withSkip
                onItemsPerPageChange={setItemsPerPage}
                onPageChange={setPage}
            />
        </>
    );
};
