import { ColumnDef } from '@tanstack/react-table';
import { FormattedCell } from '@uag/react-core';
import { t } from 'i18next';

import { LogEventModel } from 'api/v3/models';

const getEventDisplayName = (event: LogEventModel) => {
    let displayName = event.eventType
        .split('.')
        .pop() // get last part of the event type
        ?.split(/(?=[A-Z])/) // split by capital letters
        .join(' ') // join with space
        .toLowerCase()
        .replace(/^\w/, (c) => c.toUpperCase()) // capitalize first letter
        .replace(' event', '');

    switch (event.eventType.split('.').slice(3).join('.')) {
        case 'User.Events.UserLoginEvent':
            if (event.eventData && event.eventData.Success !== undefined) {
                displayName = event.eventData.Success ? ` ${t('loginSuccessfully')}` : ` ${t('loginFailed')}`;
            }
    }

    return displayName;
};

export const getColumns = (): ColumnDef<LogEventModel>[] => [
    {
        accessorFn: (row) => <FormattedCell unit="date" value={row.timeGenerated} />,
        id: 'time',
        header: () => t('time'),
        cell: ({ row }) => (
            <div>
                <FormattedCell unit="date" value={row.original.timeGenerated} />
            </div>
        ),
    },
    {
        accessorFn: (row) => row.clientName,
        id: 'client',
        header: () => t('client'),
        cell: ({ row }) => <div>{row.original.clientName}</div>,
    },
    {
        accessorFn: (row) => getEventDisplayName(row),
        id: 'action',
        header: () => t('action'),
        cell: ({ row }) => <div>{getEventDisplayName(row.original)}</div>,
    },
];
